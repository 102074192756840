<template>
    <div id="listen-device">
        <v-container>
            <v-row class="mb-5 ml-2 mt-6" no-gutters>
                <v-icon color="blue" @click="stopListening()">mdi-arrow-left</v-icon>
                <span class="little-title font-weight-bold ml-6">
                    {{ $t('listen_mode.listening_mode') }}
                </span>
            </v-row>

            <div class="text-center mt-8">
                <p class="mb-0 font-14">
                    {{ $t('listen_mode.device_listening') }}
                </p>
                <v-chip color="success">
                    {{ $store.state.successAlert.listening_device }}
                </v-chip>
            </div>

            <v-progress-linear
                :color="is_loading ? 'blue' : 'grey'"
                :indeterminate="is_loading"
                class="w-80 mt-2 mb-2 ml-auto mr-auto"
                height="2"
                rounded
            />

            <template v-if="!buildingHeaders">
                <list-check-network
                    :headers="headers"
                    :historical_traited_data="historical_traited_data"
                    :is_checking_network="is_checking_network"
                    :device="device"
                    class="mb-2"
                />
            </template>

            <div class="text-center mt-2">
                <router-link :to="{ name: 'dashboard' }" class="link" @click.native="$store.commit('successAlert/hideSuccessAlert')">
                    {{ $t('listen_mode.back_home_page') }}
                </router-link>
            </div>
        </v-container>
    </div>
</template>

<script>
import ListCheckNetwork from '../../components/ListCheckNetwork'
import Loader from '../../components/Loader'
import DeviceRepository from '../../repositories/DeviceRepository'
import metricToDisplay from '@/mixin/metricToDisplay'

export default {
    name: 'ListenDevice',
    components: {
        Loader,
        ListCheckNetwork,
    },
    mixins: [metricToDisplay],
    created() {
        this.getHistoricalTraitedData()
        this.getDeviceAndAssetDetails(this.$store.state.successAlert.listening_device)
        this.autoRefresh()
    },
    data() {
        return {
            historical_traited_data: [],
            is_checking_network: false,
            is_loading: false,
            details_asset: null,
            details_device: null,
            device:null,
        }
    },
    methods: {
        getHistoricalTraitedData() {
            this.is_loading = true
            this.is_checking_network = false
            DeviceRepository.getHistoricalTraitedData(this.$store.state.successAlert.listening_device, 10)
                .then((success) => {
                    this.historical_traited_data = success.data.data.data.data
                    this.is_checking_network = true
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.is_loading = false
                })
        },
        async getDeviceAndAssetDetails(device_number) {
            this.loading_details = true
            await Promise.all([this.getDetailsDevice(device_number), this.getAssetDetails(device_number), this.getDevice(device_number)])
            await this.buildHeadersByAsset(this.details_asset, this.details_device)
            this.loading_details = false
        },

        async getDevice(device_number){
            DeviceRepository.getDeviceByDeviceNumber(device_number)
                .then(async (success) => {
                    this.device = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        async getDetailsDevice(device_number) {
            this.details_device = null
            return DeviceRepository.getDetailsDevice(device_number)
                .then((success) => {
                    this.details_device = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        async getAssetDetails(device_number) {
            this.details_asset = null
            return DeviceRepository.getDeviceAsset(device_number)
                .then((success) => {
                    this.details_asset = success.data.data.asset
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        autoRefresh() {
            this.$store.commit('setAutoRefresh', setInterval(this.getHistoricalTraitedData, 5000))
        },

        stopListening() {
            this.$store.commit('successAlert/showSuccessAlert', true)
        },
    },
}
</script>

<style>
.link {
    text-align: center;
    color: #4c545f !important;
    text-decoration: none;
    margin-bottom: 10px;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>

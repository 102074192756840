import { CoreModule } from 'dynamsoft-core'
import { LicenseManager } from 'dynamsoft-license'
import 'dynamsoft-barcode-reader'
// Configures the root path where the .wasm files and other necessary resources for modules are located.
CoreModule.engineResourcePaths.rootDirectory = 'https://cdn.jsdelivr.net/npm/'

/** LICENSE ALERT - README
 * To use the library, you need to first specify a license key using the API "initLicense()" as shown below.
 */

LicenseManager.initLicense(
    process.env.VUE_APP_DYNAMSOFT_LICENSE_KEY
)

/**
 * You can visit https://www.dynamsoft.com/customer/license/trialLicense?utm_source=github&product=dbr&package=js to get your own trial license good for 30 days.
 * Note that if you downloaded this sample from Dynamsoft while logged in, the above license key may already be your own 30-day trial license.
 * For more information, see https://www.dynamsoft.com/barcode-reader/programming/javascript/user-guide/?ver=10.4.20&utm_source=github#specify-the-license or contact support@dynamsoft.com.
 * LICENSE ALERT - THE END
 */

// Optional. Preload "BarcodeReader" module for reading barcodes. It will save time on the initial decoding by skipping the module loading.
CoreModule.loadWasm(['DBR'])

<template>
    <div id="information-device-component">
        <v-card :loading="loading_details" class="elevation-0">
            <v-card-title class="py-2" @click="is_open = !is_open">
                <span class="font-14 mr-2">
                    {{ $t('information_device_component.device_information', { device: loading_details ? '-' : device.device_number }) }}
                </span>
                <img v-if="!loading_details" :src="device.path_image_device_referentiel" height="38" />

                <v-spacer />
                <v-icon v-if="!loading_details">
                    {{ is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
            </v-card-title>

            <v-expand-transition>
                <div v-if="is_open && !loading_details" class="hidden-information">
                    <div class="information">
                        <v-card-text class="pt-0 pb-4">
                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-tag</v-icon>
                                    {{ $t('information_device_component.name') }} :
                                </span>
                                <span class="font-weight-bold">{{ device.device_referentiel.name }}</span>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-access-point</v-icon>
                                    {{ $t('information_device_component.network') }} :
                                </span>
                                <span class="font-weight-bold">{{ device.network_display }}</span>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-check-network-outline</v-icon>
                                    {{ $t('information_device_component.total_statement_per_day') }} :
                                </span>
                                <span class="font-weight-bold">{{ device.total_statement_per_day }}</span>
                            </div>

                            <div class="my-1">
                                <v-row class="ma-0">
                                    <div class="my-auto">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-access-point-network</v-icon>
                                            {{ $t('information_device_component.last_emission') }} :
                                        </span>
                                    </div>
                                    <chips-last-statement
                                        :danger="345600"
                                        :date="device.date_last_emission"
                                        :warning="172800"
                                        class="ml-1"
                                    />
                                </v-row>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-battery-70</v-icon>
                                    {{ $t('information_device_component.battery_level') }} :
                                </span>
                                <span class="font-weight-bold">{{ device.battery }} %</span>
                            </div>

                            <div v-if="device.is_bug_hardware" class="my-1">
                                <span class="font-color-light warning--text">
                                    <v-icon class="mr-1 warning--text" dense>mdi-alert-outline</v-icon>
                                    {{ $t('information_device_component.physical_device_bug') }}
                                </span>
                            </div>

                            <div class="my-1">
                                <template v-if="device.orientation && displayToHumanAccel(device.orientation) !== ''">
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-compass-outline</v-icon>
                                        {{ $t('information_device_component.orientation') }} :
                                    </span>
                                    <span class="font-weight-bold">{{ displayToHumanAccel(device.orientation) }}</span>
                                </template>
                            </div>

                            <div
                                v-if="
                                    device.is_sentinel_active ||
                                    device.is_threshold_low_active ||
                                    device.is_threshold_high_active ||
                                    device.is_integrated_device ||
                                    device.is_treatment_adblue
                                "
                                class="my-1"
                            >
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-cogs</v-icon>
                                    {{ $t('information_device_component.activated_options') }} : </span
                                ><br />
                                <span v-if="device.is_sentinel_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.mode_sentinelle') }}<br />
                                </span>
                                <span v-if="device.is_threshold_low_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.low_threshold') }} <br />
                                </span>
                                <span v-if="device.is_threshold_high_active" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.high_threshold') }}
                                    <br />
                                </span>
                                <span v-if="device.is_integrated_device" class="font-weight-bold ml-8">
                                    - {{ $t('information_device_component.integrated_device') }}
                                    <br />
                                </span>
                                <span v-if="device.is_treatment_adblue" class="font-weight-bold ml-8">
                                    - AdBlue
                                    <br />
                                </span>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                    {{ $t('information_device_component.version') }} :
                                </span>
                                <br />

                                <span class="font-color-light ml-7"> {{ $t('information_device_component.hardware') }}: </span>
                                <span class="font-weight-bold">{{ device.version_hardware }}</span>
                                <br />

                                <span class="font-color-light ml-7"> {{ $t('information_device_component.software') }} : </span>
                                <span class="font-weight-bold">{{ device.version_software }}</span>
                            </div>

                            <!--
                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-axis-arrow</v-icon>
                                    Métriques :
                                </span>
                                <span v-for="metric in metrics" :key="metric">
                                    <v-chip class="mr-1" outlined small style="pointer-events: none">{{ metric }}</v-chip>
                                </span><br/>
                            </div>
                            -->

                            <v-divider class="my-3" />

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-credit-card-settings-outline</v-icon>
                                    {{ $t('information_device_component.subscription') }} :
                                </span>

                                <span v-if="device.contract_paid_by_professional" class="font-weight-bold">
                                    {{
                                        $t('information_device_component.subscription_paid_by_pro', {
                                            professional: device.contract.professional.distributor_name,
                                        })
                                    }}
                                </span>

                                <template v-else>
                                    <span class="font-weight-bold">
                                        {{ $t('information_device_component.from') }}
                                        {{ device.date_subscription_start | moment('Do MMMM YYYY') }}
                                        {{ $t('information_device_component.to') }}
                                        {{ device.date_subscription_end | moment('Do MMMM YYYY') }}
                                    </span>
                                    <br />
                                    <p class="font-italic font-12 ml-7 mb-1" style="position: relative; bottom: 5px">
                                        {{
                                            new Date(device.date_subscription_end) > new Date()
                                                ? $t('information_device_component.ends_in')
                                                : $t('information_device_component.completed_about')
                                        }}
                                        {{ device.date_subscription_end | moment('from', 'now', true) }}
                                    </p>
                                </template>
                            </div>

                            <div v-if="device.date_activation_device || device.date_activation_interface" class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-calendar-check</v-icon>
                                    {{ $t('information_device_component.date_activation') }}:
                                </span>
                                <br />

                                <div v-if="device.date_activation_interface">
                                    <span class="font-color-light ml-7"> {{ $t('information_device_component.interface') }} : </span>
                                    <span class="font-weight-bold">
                                        {{ device.date_activation_interface | moment('Do MMMM YYYY') }}
                                    </span>
                                </div>

                                <div v-if="device.date_activation_device">
                                    <span class="font-color-light ml-7"> {{ $t('information_device_component.device') }} : </span>
                                    <span class="font-weight-bold">
                                        {{ device.date_activation_device | moment('Do MMMM YYYY') }}
                                    </span>
                                </div>
                            </div>

                            <div class="my-1">
                                <span class="font-color-light">
                                    <v-icon class="mr-1" dense>mdi-checkbox-multiple-marked-outline</v-icon>
                                    {{ $t('information_device_component.warranty') }} :
                                </span>
                                <span class="font-weight-bold">
                                    {{
                                        new Date(device.date_warranty_end) > new Date()
                                            ? $t('information_device_component.ends_in')
                                            : $t('information_device_component.completed_about')
                                    }}
                                    {{ device.date_warranty_end | moment('from', 'now', true) }}
                                </span>
                            </div>

                            <template v-if="asset">
                                <v-divider class="my-3" />
                                <h4>{{ $t('information_device_component.equipment_information') }}</h4>

                                <div class="my-1">
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-tag-outline </v-icon>
                                        {{ $t('information_device_component.name') }} :
                                    </span>
                                    <span class="font-weight-bold"> {{ asset.name }} L </span>
                                </div>

                                <template v-if="module && module.name">
                                    <div class="d-flex align-center gap-1 pl-n2 flex-wrap">
                                        <custom-icon-component
                                            :icon="module.menu_icon"
                                            class="ml-n1"
                                            default-icon="mdi-remote"
                                            style="width: 20px; height: 20px; color: #0000008a; margin-right: 7px"
                                        />
                                        <span class="text-no-wrap">{{ $t('asset_summary_component.asset_type') }}:</span>
                                        <span class="font-weight-medium">
                                            {{ module.name }}
                                        </span>
                                    </div>
                                </template>

                                <template v-if="!asset.id_module">
                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-storage-tank-outline </v-icon>
                                            {{ $t('information_device_component.capacity') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ asset.volume_total }} L </span>
                                    </div>

                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-arrow-expand-vertical</v-icon>
                                            {{ $t('information_device_component.height') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ asset.height }} cm </span>
                                    </div>

                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-distribute-vertical-top</v-icon>
                                            {{ $t('information_device_component.fireplace') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ asset.offset }} cm </span>
                                    </div>

                                    <div v-if="asset.asset_liquid" class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-water</v-icon>
                                            {{ $t('information_device_component.liquid_type') }} :
                                        </span>
                                        <span class="font-weight-bold">
                                            {{ asset.asset_liquid.name }}
                                        </span>
                                    </div>
                                </template>

                                <template v-else-if="module && module.total_models === 1">
                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-storage-tank-outline </v-icon>
                                            {{ $t('information_device_component.capacity') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ JSON.parse(asset.configuration)[0].total_volume }} L </span>
                                    </div>

                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-arrow-expand-vertical</v-icon>
                                            {{ $t('information_device_component.height') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ JSON.parse(asset.configuration)[0].height / 10 }} cm </span>
                                    </div>

                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-distribute-vertical-top</v-icon>
                                            {{ $t('information_device_component.fireplace') }} :
                                        </span>
                                        <span class="font-weight-bold"> {{ JSON.parse(asset.configuration)[0].offset / 10 }} cm </span>
                                    </div>
                                </template>

                                <template v-else-if="asset.asset_liquid">
                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-water</v-icon>
                                            {{ $t('information_device_component.liquid_type') }} :
                                        </span>
                                        <span class="font-weight-bold">
                                            {{ asset.asset_liquid.name }}
                                        </span>
                                    </div>
                                </template>

                                <div v-if="asset.latitude !== null && asset.longitude !== null" class="my-1">
                                    <span class="font-color-light">
                                        <v-icon class="mr-1" dense>mdi-map-marker-radius-outline</v-icon>
                                        {{ $t('information_device_component.position') }} :
                                    </span>
                                    <span class="font-weight-bold">
                                        <a :href="`https://maps.google.com/?q=${asset.latitude},${asset.longitude}`" target="”_blank”">
                                            {{ asset.latitude }}, {{ asset.longitude }}<v-icon class="mr-1" dense>mdi-open-in-new</v-icon>
                                        </a>
                                    </span>
                                </div>

                                <v-divider class="my-3" />

                                <template v-if="asset.domicile">
                                    <h4>{{ $t('information_device_component.site_information') }}</h4>
                                    <div v-if="asset.domicile.address_name !== ''" class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-home</v-icon>
                                            {{ $t('information_device_component.name') }} :
                                        </span>
                                        <span class="font-weight-bold">
                                            {{ asset.domicile.address_name }}
                                        </span>
                                    </div>

                                    <div class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-map-marker-radius-outline</v-icon>
                                            {{ $t('information_device_component.address') }} :
                                        </span>

                                        <span v-if="asset.domicile.address !== ''" class="font-weight-bold">
                                            <a :href="domicileGMapsUrl" target="”_blank”">
                                                {{ asset.domicile.street_number }}, {{ asset.domicile.address }}
                                                <v-icon class="mr-1" dense>mdi-open-in-new</v-icon>
                                            </a>
                                        </span>
                                    </div>

                                    <div v-if="asset.domicile.address_2 !== ''" class="my-1">
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-map-marker-plus</v-icon>
                                            {{ $t('information_device_component.address_complement') }} :
                                        </span>
                                        <span class="font-weight-bold">
                                            {{ asset.domicile.address_2 }}
                                        </span>
                                    </div>

                                    <div
                                        v-if="
                                            asset.domicile.postal_code !== '' || asset.domicile.city !== '' || asset.domicile.country !== ''
                                        "
                                        class="my-1"
                                    >
                                        <span class="font-color-light">
                                            <v-icon class="mr-1" dense>mdi-city</v-icon>
                                            {{ $t('information_device_component.city_country') }} :
                                        </span>

                                        <span class="font-weight-bold">
                                            {{ asset.domicile.postal_code }}, {{ asset.domicile.city }}
                                            {{ asset.domicile.country }}
                                        </span>
                                    </div>

                                    <div v-if="showContactInformations">
                                        <v-divider class="my-3" />
                                        <h4>{{ $t('information_device_component.onsite_contact_information') }}</h4>
                                        <div
                                            v-if="asset.domicile.contact_first_name !== '' || asset.domicile.contact_last_name !== ''"
                                            class="my-1"
                                        >
                                            <v-icon class="mr-1" dense>mdi-account</v-icon>
                                            {{ $t('information_device_component.contact') }} :
                                            <span class="font-weight-bold">
                                                {{ asset.domicile.contact_first_name }} {{ asset.domicile.contact_last_name }}
                                            </span>
                                        </div>

                                        <div v-if="asset.domicile.contact_mail !== ''" class="my-1">
                                            <v-icon class="mr-1" dense>mdi-email-outline</v-icon>
                                            {{ $t('information_device_component.email') }} :
                                            <span class="font-weight-bold">
                                                {{ asset.domicile.contact_mail }}
                                            </span>
                                        </div>

                                        <div v-if="asset.domicile.contact_phone !== ''" class="my-1">
                                            <v-icon class="mr-1" dense>mdi-phone-outline</v-icon>
                                            {{ $t('information_device_component.phone') }} :
                                            <span class="font-weight-bold">
                                                {{ asset.domicile.contact_phone }}
                                            </span>
                                        </div>

                                        <div v-if="asset.domicile.contact_note !== ''" class="my-1">
                                            <v-icon class="mr-1" dense>mdi-note-outline</v-icon>
                                            {{ $t('information_device_component.note') }} :&nbsp
                                            <span class="font-weight-bold">
                                                <pre class="text-wrap">{{ asset.domicile.contact_note }}</pre>
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </template>

                            <div v-if="client">
                                <v-divider class="my-3" />
                                <h4>{{ $t('information_device_component.information_sens') }}</h4>
                                <div v-if="client.client_space_name !== ''" class="my-1">
                                    <v-icon class="mr-1" dense>mdi-account</v-icon>
                                    {{ $t('information_device_component.name') }} :
                                    <span class="font-weight-bold">
                                        {{ client.client_space_name }}
                                    </span>
                                </div>

                                <div v-if="client.company_name !== ''" class="my-1">
                                    <v-icon class="mr-1" dense>mdi-office-building</v-icon>
                                    {{ $t('information_device_component.company') }} :
                                    <span class="font-weight-bold">
                                        {{ client.company_name }}
                                    </span>
                                </div>
                            </div>
                        </v-card-text>
                    </div>
                </div>
            </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import ChipsLastStatement from '@/components/ChipsLastStatement'
import moment from 'moment'
import CustomIconComponent from '@/components/CustomIconComponent.vue'

export default {
    name: 'InformationDeviceComponent',
    methods: {
        moment() {
            return moment
        },
    },
    components: {
        CustomIconComponent,
        ChipsLastStatement,
    },
    props: {
        device: {
            type: Object | null,
            required: true,
        },
        asset: {
            type: Object | null,
            required: false,
        },
        module: {
            type: Object | null,
            required: false,
        },
        client: {
            type: Object | null,
            required: false,
        },
        loading_details: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        showContactInformations: function () {
            return (
                this.asset.domicile.contact_first_name !== '' ||
                this.asset.domicile.contact_last_name !== '' ||
                this.asset.domicile.contact_mail !== '' ||
                this.asset.domicile.contact_phone !== '' ||
                this.asset.domicile.contact_note !== ''
            )
        },
        domicileGMapsUrl: function () {
            let url = `https://maps.google.com/?q=${this.client ? this.client.company_name : ''}, ${this.asset.domicile.street_number} ${
                this.asset.domicile.address
            } ${this.asset.domicile.city} ${this.asset.domicile.country}`
            url = url.replace('&', '')
            return encodeURI(url)
        },
    },
    data() {
        return {
            metrics: ['Niveau', 'Température', 'GPS'],
            is_open: false,
        }
    },
}
</script>

<style>
.hidden-information {
    position: relative;
    overflow: hidden;
}

h4 {
    color: #757f8c;
}
</style>

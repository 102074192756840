<template>
    <div id="qr-code-scanner-component">
        <input ref="scannerInput" accept="image/*" style="visibility: hidden; position: absolute" type="file" @change="handleFileChange" />

        <div v-if="isScannerOpen">
            <div ref="cameraViewContainer" class="camera-scanner-wrapper" />
            <div class="scanner-options-btn camera-btn" @click="selectFile">
                <v-icon color="white">mdi-camera</v-icon>
            </div>

            <div class="scanner-options-btn back-btn" @click="stopScanner">
                <v-icon color="white">mdi-arrow-left</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
import '../../dynamsoft.config'
import { CaptureVisionRouter } from 'dynamsoft-capture-vision-router'
import { CameraEnhancer, CameraView } from 'dynamsoft-camera-enhancer'
import { nextTick } from 'vue'

export default {
    name: 'QrCodeScannerComponent',
    data() {
        return {
            cvRouter: null,
            pCvRouter: null,
            isDestroyed: false,

            pCameraEnhancer: null,
            isCameraDestroyed: false,

            isScannerOpen: false,
        }
    },
    beforeDestroy: async function () {
        this.isDestroyed = true
        await this.pCvRouter // ensure cvrouter creation is complete
        if (this.cvRouter) {
            this.cvRouter.dispose() // dispose cvRouter if it exists
        }
        this.cvRouter = null // reset cvRouter to null
    },
    methods: {
        async initScanner() {
            if (!this.isCameraDestroyed) {
                await this.stopScanner()
            }
            this.isCameraDestroyed = false
            this.isScannerOpen = true
            nextTick(async () => {
                const cameraView = await CameraView.createInstance('@engineResourcePath/dce.mobile-native.ui.html')
                if (this.isCameraDestroyed) {
                    return
                }

                const UI = cameraView.getUIElement().shadowRoot.querySelector('.dce-macro-use-mobile-native-like-ui')
                UI.querySelector('.dce-mn-msg-poweredby').style = 'display: none'
                UI.querySelector('.dce-mn-beep').style = 'display: none'
                UI.querySelector('.dce-mn-resolution-box').style = 'display: none'

                const torchClass = ['.dce-mn-torch-auto', '.dce-mn-torch-on', '.dce-mn-torch-off']
                torchClass.forEach((torch_btn) => {
                    UI.querySelector(torch_btn).style.right = '16px'
                    UI.querySelector(torch_btn).style.left = 'auto'
                    UI.querySelector(torch_btn).style.width = '46px'
                    UI.querySelector(torch_btn).style.height = '46px'
                    UI.querySelector(torch_btn).style.borderRadius = '100%'
                })

                UI.querySelector('.dce-mn-torch-on').style.right = '16px'
                UI.querySelector('.dce-mn-torch-on').style.left = 'auto'

                UI.querySelector('.dce-mn-torch-off').style.right = '16px'
                UI.querySelector('.dce-mn-torch-off').style.left = 'auto'

                this.cameraEnhancer = await (this.pCameraEnhancer || CameraEnhancer.createInstance(cameraView))
                if (this.isCameraDestroyed) {
                    return
                }

                // Get default UI and append it to DOM.
                this.$refs.cameraViewContainer.append(cameraView.getUIElement())

                // Create a `CaptureVisionRouter` instance and set `CameraEnhancer` instance as its image source.
                this.cvRouter = await (this.pCvRouter || CaptureVisionRouter.createInstance())
                if (this.isCameraDestroyed) {
                    return
                }

                this.cvRouter.setInput(this.cameraEnhancer)

                // Define a callback for results.
                this.cvRouter.addResultReceiver({
                    onDecodedBarcodesReceived: (result) => {
                        if (!result.barcodeResultItems.length) return
                        this.qrCodeSuccessCallback(result.barcodeResultItems[0].text)
                    },
                })

                // Open camera and start scanning single barcode.
                await this.cameraEnhancer.open()
                if (this.isCameraDestroyed) {
                    return
                }

                await this.cvRouter.startCapturing('ReadSingleBarcode')
            })
        },

        selectFile() {
            this.$refs.scannerInput.click()
        },

        async stopScanner() {
            this.isCameraDestroyed = true
            await this.pCameraEnhancer
            if (this.cameraEnhancer) {
                this.cameraEnhancer.dispose()
            }
            this.cameraEnhancer = null
            this.isScannerOpen = false
        },

        async handleFileChange(e) {
            let file = e.target.files[0]
            e.target.value = '' // reset input

            // ensure cvRouter is created only once
            this.cvRouter = await (this.pCvRouter = this.pCvRouter || CaptureVisionRouter.createInstance())
            if (this.isDestroyed) {
                return
            }

            let result = await this.cvRouter.capture(file, 'ReadBarcodes_SpeedFirst')
            if (this.isDestroyed) {
                return
            }

            this.qrCodeSuccessCallback(result.items[0].text)
        },

        qrCodeSuccessCallback(decodedText) {
            this.stopScanner()
            this.$emit('scanned', decodedText)
        },

        cancel() {
            this.$emit('cancel')
        },
    },
}
</script>

<style>
.scanner-options-btn {
    &.camera-btn {
        top: 66px;
        right: 16px;
    }

    &.back-btn {
        top: 12px;
        left: 16px;
    }

    position: fixed;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    color: white;
    background-color: rgba(100, 100, 100, 0.5);
    border-radius: 100%;
    cursor: pointer;
}

.camera-scanner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}
</style>

<template>
    <div id="add-asset-liquid-dialog-component">
        <v-dialog v-model="dialog_add_asset_liquid" max-width="600">
            <v-card id="add-asset-liquid-dialog">
                <v-card-title class="card-title">
                    {{ $t('edit_equipment.add_new_liquid') }}
                </v-card-title>

                <v-card-text class="mt-4 pb-0">
                    <v-form ref="form_add_asset_liquid">
                        <v-text-field
                            v-model="asset_liquid.label"
                            :label="$t('edit_equipment.liquid_name')"
                            :rules="[
                                (v) => !!v || this.$t('edit_equipment.require_label'),
                                (v) => v.length < 50 || this.$t('edit_equipment.too_long_label'),
                            ]"
                            filled
                        />

                        <color-picker-component v-on:confirmLiquidChoose="confirmLiquidChoose" />

                        <v-text-field
                            v-model="asset_liquid.density"
                            :label="$t('edit_equipment.density')"
                            :rules="[(v) => !!v || this.$t('edit_equipment.require_density')]"
                            filled
                            type="number"
                        />

                        <v-text-field v-model="asset_liquid.code_article" :label="$t('edit_equipment.code_article')" filled />

                        <generic-form-component
                            :configuration="configuration"
                            :form_inputs="form_input"
                            v-on:update:configuration="handleConfigurationUpdate"
                        />
                    </v-form>
                </v-card-text>

                <v-container class="px-6">
                    <v-btn
                        :loading="loading_add_asset_liquid"
                        block
                        class="validation-button"
                        color="primary"
                        large
                        @click.prevent="addNewAssetLiquid()"
                    >
                        {{ $t('global.confirm') }}
                    </v-btn>

                    <v-btn block class="cancel-btn mt-1" large text @click="dialog_add_asset_liquid = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ColorPickerComponent from '@/components/ColorPickerComponent.vue'
import AssetLiquidRepository from '@/repositories/AssetLiquidRepository'
import GenericFormComponent from '@/components/GenericFormComponent.vue'

export default {
    name: 'AddAssetLiquidDialogComponent',
    components: { GenericFormComponent, ColorPickerComponent },
    data() {
        return {
            dialog_add_asset_liquid: false,
            loading_add_asset_liquid: false,
            configuration: {},
            form_input: {},
            asset_liquid: {
                label: '',
                id_ref_contenant: null,
                color: '#CCCCCC',
                density: 1,
                code_article: '',
                id_professional: null,
                configuration: {},
                id_module: null,
            },
        }
    },
    methods: {
        openAddAssetLiquidDialog(asset_liquid, asset_module = null) {
            this.asset_liquid = asset_liquid

            if (asset_module) {
                this.asset_liquid.id_module = asset_module.id_module
                this.configuration = {}
                this.form_input = asset_module.configuration_form_asset_liquid
            }

            this.dialog_add_asset_liquid = true
        },

        handleConfigurationUpdate(newConfiguration) {
            this.configuration = newConfiguration.form
            this.asset_liquid.configuration = this.configuration
        },

        confirmLiquidChoose(color) {
            this.asset_liquid.color = color
        },

        addNewAssetLiquid() {
            if (this.$refs.form_add_asset_liquid.validate()) {
                this.loading_add_asset_liquid = true

                AssetLiquidRepository.postAddAssetLiquid(this.asset_liquid)
                    .then(() => {
                        this.$store.commit('snackbar/showSnackbar', {
                            color: 'success',
                            text: this.$t('edit_equipment.liquid_added'),
                        })

                        this.$emit('add:liquid')
                        this.$refs.form_add_asset_liquid.reset()
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading_add_asset_liquid = false
                        this.dialog_add_asset_liquid = false
                    })
            }
        },
    },
}
</script>

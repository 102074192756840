<template>
    <div v-if="asset.id_asset" class="d-flex flex-column gap-4">
        <asset-summary-component :asset="asset" />
        <manage-asset-image-component :asset="asset" />

        <widget-wrapper-component :title="$t('edit_asset_of_asset_module_form_component.asset')" is-already-open>
            <template v-slot:icon>
                <custom-icon-component default-icon="mdi-remote" icon="mdi-storage-tank-outline" />
            </template>

            <template v-slot:default>
                <div>
                    <span class="section_title">
                        {{ $t('edit_equipment.equipment_information') }}
                    </span>

                    <v-text-field
                        v-model="asset.name"
                        :label="$t('edit_equipment.equipment_name')"
                        :rules="require_field"
                        class="mt-2"
                        filled
                    />

                    <v-select
                        v-model="asset.id_liquid"
                        :disabled="asset_liquids.length === 0"
                        :items="asset_liquids"
                        :label="$t('edit_equipment.content')"
                        :loading="loading_content"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="require_field"
                        filled
                        item-text="name"
                        item-value="id_asset_liquid"
                    >
                        <template v-slot:prepend-item>
                            <v-btn block size="large" text @click="openAddAssetLiquidDialog()">
                                <v-icon>mdi-plus</v-icon>
                                {{ $t('global.add') }}
                            </v-btn>
                        </template>
                    </v-select>
                </div>

                <div>
                    <span class="section_title">{{ $t('edit_equipment.equipment_model') }}</span>
                    <v-switch v-model="know_model" class="mt-1" color="info">
                        <template v-slot:label>
                            <span class="switch-label">
                                {{ $t('edit_equipment.know_equipment_model') }}
                            </span>
                        </template>
                    </v-switch>

                    <div v-if="know_model">
                        <v-select
                            v-model="selected_brand_reference"
                            :disabled="brand_reference.length === 0"
                            :items="brand_reference"
                            :label="$t('edit_equipment.equipment_brand')"
                            :loading="loading_brand"
                            :menu-props="{ bottom: true, offsetY: true }"
                            filled
                            item-text="name"
                            item-value="id_brand_reference"
                            @change="getModelReference()"
                        />

                        <v-select
                            v-model="selected_model_reference"
                            :disabled="model_reference.length === 0"
                            :items="model_reference"
                            :label="$t('edit_equipment.model_name')"
                            :loading="loading_model"
                            :menu-props="{ bottom: true, offsetY: true }"
                            filled
                            item-text="trade_name"
                            item-value="id_model_reference"
                            return-object
                        />

                        <label class="text">
                            {{ $t('edit_equipment.default_offset_model') }} :
                            {{ selected_model_reference.offset }}
                            <v-text-field
                                v-model="model_offset"
                                :label="$t('edit_equipment.offset')"
                                :rules="require_field"
                                filled
                                type="number"
                            />

                            <v-text-field
                                v-model="model_offset_bottom"
                                :label="$t('edit_equipment.offset_bottom')"
                                :rules="require_field"
                                filled
                                type="number"
                            />
                        </label>
                    </div>

                    <div v-else>
                        <v-radio-group v-model="asset.type" class="mt-0">
                            <v-row class="justify-center text-center">
                                <v-col class="radio-tank" cols="3">
                                    <v-radio :value="3" class="justify-center mb-0" />
                                    <v-img
                                        class="ml-auto mr-auto mt-2 mb-2"
                                        max-height="150px"
                                        max-width="150px"
                                        src="../assets/images/Cuve-cylindrique-debout.png"
                                    />
                                    <p class="img-text">
                                        {{ $t('edit_equipment.vertical_cylindrical') }}
                                    </p>
                                </v-col>

                                <v-col class="radio-tank" cols="3">
                                    <v-radio :value="1" class="justify-center mb-0" />
                                    <v-img
                                        class="ml-auto mr-auto mt-2 mb-2"
                                        max-height="150px"
                                        max-width="150px"
                                        src="../assets/images/cuve-cubique.png"
                                    />
                                    <p class="img-text">
                                        {{ $t('edit_equipment.rectangular') }}
                                    </p>
                                </v-col>

                                <v-col class="text-center radio-tank" cols="3">
                                    <v-radio :value="5" class="justify-center mb-0" />
                                    <v-img
                                        class="ml-auto mr-auto mt-2 mb-2"
                                        max-height="150px"
                                        max-width="150px"
                                        src="../assets/images/cuve-IBC.png"
                                    />
                                    <p class="img-text">
                                        {{ $t('edit_equipment.ibc') }}
                                    </p>
                                </v-col>

                                <v-col class="text-center radio-tank" cols="3">
                                    <v-radio :value="2" class="justify-center mb-0" />
                                    <v-img
                                        class="ml-auto mr-auto mt-2 mb-2"
                                        max-height="150px"
                                        max-width="150px"
                                        src="../assets/images/cuve_cylindrique1_checkbox.png"
                                    />
                                    <p class="img-text">
                                        {{ $t('edit_equipment.cylindrical_lying') }}
                                    </p>
                                </v-col>
                            </v-row>
                        </v-radio-group>

                        <div>
                            <v-text-field
                                v-model="asset.volume_total"
                                :label="$t('edit_equipment.volume_L')"
                                :rules="require_field"
                                filled
                            />
                            <v-text-field v-model="asset.height" :label="$t('edit_equipment.height_cm')" :rules="require_field" filled />
                            <v-text-field
                                v-model="asset.length"
                                :label="$t('edit_equipment.inside_length_cm')"
                                :rules="require_field"
                                filled
                            />
                            <v-text-field
                                v-model="asset.width"
                                :label="$t('edit_equipment.inside_width_cm')"
                                :rules="require_field"
                                filled
                            />

                            <v-text-field
                                v-model="asset.offset"
                                :label="$t('edit_equipment.offset')"
                                :rules="require_field"
                                filled
                                type="number"
                            />

                            <v-text-field
                                v-model="asset.offset_bottom"
                                :label="$t('edit_equipment.offset_bottom')"
                                :rules="require_field"
                                filled
                                type="number"
                            />
                        </div>
                    </div>
                </div>

                <v-btn :class="invalid ? '' : 'validation-button'" :disabled="invalid" block class="mt-4" large @click="editEquipment">
                    {{ $t('global.update') }}
                </v-btn>
            </template>
        </widget-wrapper-component>

        <associate-client-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
        <associate-site-to-asset-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
        <localisation-asset-widget-component :asset="asset" v-on:refresh:form="$emit('refresh:form')" />
        <manage-note-admin-device-widget-component :device_number="device_number" />
        <add-asset-liquid-dialog-component ref="AddAssetLiquidDialog" v-on:add:liquid="$emit('refresh:liquid')" />
    </div>
</template>

<script>
import ManageAssetImageComponent from '@/components/ManageAssetImageComponent.vue'
import AssetRepository from '@/repositories/AssetRepository'
import BrandReferenceRepository from '@/repositories/BrandReferenceRepository'
import ModelRepository from '@/repositories/ModelRepository'
import AddAssetLiquidDialogComponent from '@/components/AddAssetLiquidDialogComponent.vue'
import AssociateSiteToAssetWidgetComponent from '@/components/site/AssociateSiteToAssetWidgetComponent.vue'
import LocalisationAssetWidgetComponent from '@/components/LocalisationAssetWidgetComponent.vue'
import AssociateClientWidgetComponent from '@/components/client/AssociateClientWidgetComponent.vue'
import ManageNoteAdminDeviceWidgetComponent from '@/components/ManageNoteAdminDeviceWidgetComponent.vue'
import AssetSummaryComponent from '@/components/AssetSummaryComponent.vue'
import WidgetWrapperComponent from '@/components/WidgetWrapperComponent.vue'
import CustomIconComponent from '@/components/CustomIconComponent.vue'

export default {
    name: 'DefaultEquipmentFormComponent',
    components: {
        CustomIconComponent,
        WidgetWrapperComponent,
        AssetSummaryComponent,
        ManageNoteAdminDeviceWidgetComponent,
        AssociateClientWidgetComponent,
        LocalisationAssetWidgetComponent,
        AssociateSiteToAssetWidgetComponent,
        AddAssetLiquidDialogComponent,
        ManageAssetImageComponent,
    },
    props: {
        device_number: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            asset: {},

            asset_liquids: [],
            loading_content: false,

            know_model: false,

            brand_reference: [],
            selected_brand_reference: '',
            loading_brand: false,

            model_reference: [],
            selected_model_reference: '',
            loading_model: false,

            model_offset: null,
            model_offset_bottom: null,

            require_field: [(v) => !!v || 'Champs requis'],
            properties: ['volume_total', 'height', 'length', 'width'],

            asset_liquid: {
                label: '',
                id_ref_contenant: null,
                color: '#CCCCCC',
                density: 1,
                id_professional: null,
            },

            email_rules: [
                (v) =>
                    v === '' ||
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        v,
                    ) ||
                    'Email non valide',
            ],
            defaultDomicile: null,
        }
    },
    computed: {
        invalid: function () {
            return !(
                this.device_number !== '' &&
                this.asset.name !== '' &&
                this.asset.id_liquid !== '' &&
                ((this.know_model &&
                    this.selected_brand_reference !== '' &&
                    this.selected_model_reference !== '' &&
                    this.model_offset != null &&
                    this.model_offset_bottom != null) ||
                    (!this.know_model &&
                        this.asset.type !== '' &&
                        this.asset.type !== 0 &&
                        this.asset.offset !== '' &&
                        this.asset.offset_bottom !== '' &&
                        this.asset.length !== '' &&
                        this.asset.width !== '' &&
                        this.asset.height !== '' &&
                        this.asset.volume_total !== ''))
            )
        },
    },
    watch: {
        'asset.type'() {
            this.refreshPropertyValueIfTankTypeChange()
        },
    },
    methods: {
        getDeviceAsset(data, value) {
            this.asset_data = data
            this.asset = data.asset
            this.defaultDomicile = { ...data.asset.domicile }
            this.asset_liquids = data.asset_liquids

            this.getBrandReference()
            if (data.asset.brand_reference !== null && data.asset.model_reference !== null) {
                this.know_model = true
                this.selected_brand_reference = data.asset.brand_reference.id_brand_reference
                this.selected_model_reference = data.asset.model_reference
                this.getModelReference()
            }
            this.device_number = value
            this.model_offset = data.asset.offset
            this.model_offset_bottom = data.asset.offset_bottom

            this.asset_liquid.id_professional = data.asset.id_professional
            this.asset_liquid.id_ref_contenant = data.asset.id_ref_contenant
            this.asset_liquid.color = '#CCCCCC'
            this.asset_liquid.density = 1
        },

        openAddAssetLiquidDialog() {
            this.$refs.AddAssetLiquidDialog.openAddAssetLiquidDialog(this.asset_liquid)
        },

        getBrandReference() {
            this.loading_brand = true
            BrandReferenceRepository.geBrandReference()
                .then((success) => {
                    this.brand_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_brand = false
                })
        },

        getModelReference() {
            this.model_reference = []
            this.loading_model = true
            ModelRepository.getModelReference(this.selected_brand_reference)
                .then((success) => {
                    this.model_reference = success.data.data
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.loading_model = false
                })
        },

        editEquipment() {
            this.$store.commit('loader/showLoader', this.$t('edit_equipment.equipment_update'))

            let asset = {}
            if (this.know_model) {
                asset = {
                    know_model_brand_asset: true,
                    asset_name: this.asset.name,
                    id_brand_reference: this.selected_brand_reference,
                    id_model_reference: this.selected_model_reference.id_model_reference,
                    id_liquid: this.asset.id_liquid,
                    offset: this.model_offset,
                    offset_bottom: this.model_offset_bottom,
                    is_mobile: false,
                    index_monitoring_type: '-1',
                    latitude: this.asset.latitude,
                    longitude: this.asset.longitude,
                }
            } else {
                asset = {
                    height: this.asset.height,
                    asset_name: this.asset.name,
                    volume: this.asset.volume_total,
                    offset: this.asset.offset,
                    offset_bottom: this.asset.offset_bottom,
                    width: this.asset.width,
                    length: this.asset.length,
                    tank_type: this.asset.type,
                    is_mobile: false,
                    index_monitoring_type: '-1',
                    id_liquid: this.asset.id_liquid,
                    know_model_brand_asset: false,
                    latitude: this.asset.latitude,
                    longitude: this.asset.longitude,
                }
            }

            AssetRepository.putEditAsset(this.asset.id_asset, asset, null)
                .then(() => {
                    this.$store.commit('successAlert/showSuccessAlert', true)
                    this.$store.commit('successAlert/addMessageToSuccessAlert', {
                        value_top: this.$t('edit_equipment.equipment_associate_with_device_updated', {
                            asset: this.asset.name,
                            device: this.device_number,
                        }),
                        value_bottom: '',
                        type: 'success',
                    })
                    this.$store.commit('successAlert/setListeningDevice', this.device_number)
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.$store.commit('loader/hideLoader')
                })
        },

        getTotalPropertySet() {
            let totalPropertySet = 0
            this.properties.forEach((property_name) => {
                if (this.asset[property_name] !== '' && this.asset[property_name] !== null) {
                    totalPropertySet++
                }
            })

            return totalPropertySet
        },

        getPropertyNotSet() {
            let propertyNotSet = ''
            this.properties.forEach((property) => {
                if (this.asset[property] === '' || this.asset[property] === null) {
                    propertyNotSet = property
                }
            })

            return propertyNotSet
        },

        prepareCalculationAutocompletionTank(property_name) {
            if (this.asset.type !== null) {
                switch (this.asset.type) {
                    case 1: {
                        this.calculateRectangularType(property_name)
                        break
                    }
                    case 2: {
                        this.calculateCylindricalHorizontalType(property_name)
                        break
                    }
                    case 3: {
                        this.calculateCylindricalVerticalType(property_name)
                        break
                    }
                    case 5: {
                        this.calculateIbcType(property_name)
                        break
                    }
                }
            }
        },

        calculateRectangularType(property_name) {
            if (this.getTotalPropertySet() === 3) {
                if (this.asset.volume_total === null && this.asset.volume_total === '') {
                    this.asset.volume_total = Math.floor((this.asset.width * this.asset.height * this.asset.length) / 1000)
                } else {
                    let propertyValue = 1
                    this.properties.forEach((property) => {
                        if (this.asset[property] !== '' && this.asset[property] !== null && property !== 'volume_total') {
                            propertyValue = propertyValue * this.asset[property]
                        }
                    })
                    this.asset[this.getPropertyNotSet()] = Math.floor((this.asset.volume_total * 1000) / propertyValue)
                }
            }

            if (this.getTotalPropertySet() === 4) {
                if (property_name === 'width') {
                    this.asset.volume_total = Math.floor((this.asset.width * this.asset.height * this.asset.length) / 1000)
                } else {
                    this.asset.width = Math.floor((this.asset.volume_total * 1000) / (this.asset.length * this.asset.height))
                }
            }
        },

        calculateCylindricalHorizontalType(property_name) {
            if (property_name === 'height' && this.asset.height) {
                this.asset.width = this.asset.height
            } else if (property_name === 'width' && this.asset.width) {
                this.asset.height = this.asset.width
            }

            if (this.asset.volume_total && this.asset.height && this.asset.width) {
                switch (property_name) {
                    case 'length':
                        this.asset.height = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.length)
                        this.asset.width = this.asset.height
                        break
                    case 'volume_total':
                    case 'height':
                        this.asset.length = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.height)
                        break
                }
            }

            if (this.asset.volume_total && this.asset.height && !this.asset.length) {
                this.asset.length = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.height)
            } else if (!this.asset.volume_total && this.asset.height && this.asset.length) {
                this.asset.volume_total = this.calculateByWidthAndLength(this.asset.height, this.asset.length)
            } else if (this.asset.volume_total && this.asset.length && this.asset.height) {
                this.asset.height = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.length)
            }
        },

        calculateCylindricalVerticalType(property_name) {
            if (property_name === 'width' && this.asset.width) {
                this.asset.length = this.asset.width
            } else if (property_name === 'length' && this.asset.length) {
                this.asset.width = this.asset.length
            }

            if (this.asset.volume_total && this.asset.height && this.asset.width) {
                switch (property_name) {
                    case 'width':
                        this.asset.height = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.width)
                        break
                    case 'volume_total':
                    case 'length':
                    case 'height':
                        this.asset.width = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.height)
                        this.asset.length = this.asset.width
                        break
                }
            }

            if (this.asset.volume_total && this.asset.length && !this.asset.width) {
                this.asset.width = this.calculateByVolumeAndX(this.asset.volume_total, this.asset.height)
                this.asset.length = this.asset.width
            } else if (!this.asset.volume_total && this.asset.height && this.asset.width) {
                this.asset.volume_total = this.calculateByWidthAndLength(this.asset.width, this.asset.height)
            } else if (this.asset.volume_total && this.asset.width && !this.asset.length) {
                this.asset.height = this.calculateByVolumeAndY(this.asset.volume_total, this.asset.width)
            }
        },

        calculateByVolumeAndY(x, y) {
            return Math.floor((x * 1000) / (Math.PI * ((y / 2) * (y / 2))))
        },

        calculateByVolumeAndX(x, y) {
            return Math.floor(2 * Math.sqrt((x * 1000) / (Math.PI * y)))
        },

        calculateByWidthAndLength(x, y) {
            return Math.floor((Math.PI * (x / 2) * (x / 2) * y) / 1000)
        },

        calculateIbcType(property_name) {
            if (this.getTotalPropertySet() >= 2) {
                if (property_name === 'volume_total') {
                    this.setPropertyValueTank(Math.round((Math.round(Math.pow(this.asset.volume_total, 1 / 3) * 100) / 100) * 10))
                } else {
                    this.asset.volume_total = Math.round(Math.pow(this.asset[property_name], 3) / 1000)
                    this.setPropertyValueTank(this.asset[property_name])
                }
            }
        },

        setPropertyValueTank(property_value) {
            this.asset.height = property_value
            this.asset.length = property_value
            this.asset.width = property_value
        },

        refreshPropertyValueIfTankTypeChange() {
            if (this.getTotalPropertySet() >= 2) {
                this.prepareCalculationAutocompletionTank('volume_total')
            }
        },
    },
}
</script>

<style>
.section_title {
    font-size: 14px;
    color: #4c545f;
    font-weight: bold;
}

.img-text {
    font-size: 12px;
    color: #4c545f;
    line-height: 14px;
    margin-bottom: 0 !important;
}

.radio-tank .v-input--selection-controls__input {
    margin-right: 0 !important;
}

.pac-target-input {
    background-color: white;
    padding: 15px;
    margin-bottom: 25px;
}
</style>
